#reward {
  display: block;
  width: 70%;
  margin: 0 auto;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    width: 90%;
  }
}

.hidden {
  visibility: hidden;
}

button.animated {
  position: relative;
  animation: rewardNextAnimation 0.3s linear;
  animation-direction: normal;
  animation-iteration-count: 1;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    border: 20px solid transparent;
    border-top: 20px solid white;
    border-right: 20px solid white;
    transform: translate(-20px, 0px) rotate(45deg);
    content: '';
    opacity: 0;
    animation: rewardNextAnimationArrow 0.3s linear;
    animation-delay: 0.3s;
    animation-direction: normal;
    animation-iteration-count: 1;
  }
}

.reward-content {
  display: block;
  position: relative;
  max-height: 100%;
  padding: 0 20px 40px 20px;
  overflow-y: auto;

  @media screen and (max-width: 768px) {
    overflow-y: scroll;
  }

  h1 {
    text-transform: uppercase;
    text-shadow: #333 3px 0px 0px;
  }

  p {
    padding-right: 40px;
    font-size: 0.8em;

    @media screen and (max-width: 768px) {
      padding-right: 10px;
    }
  }

  img {
    display: block;
    max-width: 100%;
    max-height: 50vh;
    margin: 0 auto;
    box-shadow: #333 0px 3px 3px 1px;
  }
}

@keyframes rewardNextAnimation {
  0% {
    transform: scale(1);
    opacity: 0;
  }

  70% {
    transform: scale(1.25);
    opacity: 1;
  }

  100% {
    transform: scale(1);
  }
}

@keyframes rewardNextAnimationArrow {
  0% {
    left: 0%;
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  100% {
    left: 120%;
    opacity: 0;
  }
}