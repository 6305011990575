$gap: 2px;
$border: 2px;
$boardSize: 310px;
$tileSize: calc((#{$boardSize} - (var(--size, 4) - 1) * #{$gap}) / var(--size, 4));

.tile-content {
  &.fadein {
    opacity: 1;
    animation: fadein 0.7s linear;
    animation-direction: normal;
    animation-iteration-count: 1;
  }

  &.fadeout {
    opacity: 0;
    animation: fadein 2s linear;
    animation-direction: reverse;
    animation-iteration-count: 1;
  }
}

#tile-board {
  display: flex;
  flex-direction: column;
  min-width: 70vw;
  margin: 0 auto;
  gap: $gap;

  div.row {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-flow: space-evenly;
    align-items: stretch;
    align-content: stretch;
    justify-content: center;
    gap: $gap;
  }

  div.tile {
    position: relative;
    background-color: var(--tileColor, #ddd);
    background-image: url('./baby_feet.jpg');
    background-size: $boardSize;
    background-position-x: calc((var(--size, 4) - var(--j, 0))/var(--size, 4) * #{$boardSize} - #{$gap}/2);
    background-position-y: calc((var(--size, 4) - var(--i, 0))/var(--size, 4) * #{$boardSize} - #{$gap}/2);
    flex: 0 1 $tileSize;
    height: $tileSize;
    border-top: $border solid grey;
    border-left: $border solid grey;
    border-right: $border solid black;
    border-bottom: $border solid black;
    cursor: pointer;
    transition: all 0.3s;
    filter: #{ "grayscale(" + var(--tileGS, 0) + ")"} brightness(var(--tileBright, 1));

    &:hover {
      filter: #{ "grayscale(" + var(--tileGS, 0) + ")"} brightness(var(--tileBrightHover, 1.2));
    }

    &, &:before, &:after {
      border-radius: calc(25px * (1 - var(--size, 4) / 10));
    }

    &.on {
      --tileColor: #333;
      --tileGS: 1;
      --tileBright: 0.20;
      --tileBrightHover: 0.35;
    }

    &.off {
      --tileColor: #ddd;
      --tileGS: 0;
      --tileBright: 1;
      --tileBrightHover: 1.2;

      &:hover {
        border-top-color: #bbb;
        border-left-color: #bbb;
        border-right-color: #333;
        border-bottom-color: #333;
      }
    }

    &.highlight {
      --tileColor: rgb(47, 235, 172);
      animation: highlight-animation 2.5s linear;
      animation-iteration-count: 1;

      &:before {
        position: absolute;
        background-size: contain;
        background-color: rgb(47, 235, 172);
        top: -$border;
        bottom: -$border;
        left: -$border;
        right: -$border;
        animation: image-fade 2.5s linear;
        animation-iteration-count: 1;
        opacity: 0;
        content: '';
      }
    }
  }
}

#tile-board.win .tile:after {
  position: absolute;
  top: -$border;
  bottom: -$border;
  left: -$border;
  right: -$border;
  animation: rainbow-bg 2.5s linear;
  animation-iteration-count: infinite;
  animation-delay: var(--delay);
  content: '';
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes highlight-animation {
  0%, 100% {
    filter: #{ "grayscale(" + var(--tileGS, 0) + ")"} brightness(var(--tileBright, 1.2));
  }

  10% {
    filter: #{ "grayscale(" + 0 + ")"} brightness(1);
  }
}

@keyframes image-fade {
  0%, 100% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }
}

@keyframes rainbow-bg {
  100%,0%{
    background-color: rgb(255,0,127);
  }
  8%{
    background-color: rgb(224, 75, 75);
  }
  16%{
    background-color: rgb(231, 129, 27);
  }
  25%{
    background-color: rgb(247, 244, 88);
  }
  33%{
    background-color: rgb(55, 219, 33);
  }
  41%{
    background-color: rgb(68, 224, 120);
  }
  50%{
    background-color: rgb(47, 235, 172);
  }
  58%{
    background-color: rgb(0,255,255);
  }
  66%{
    background-color: rgb(51, 151, 233);
  }
  75%{
    background-color: rgb(57, 57, 209);
  }
  83%{
    background-color: rgb(127,0,255);
  }
  91%{
    background-color: rgb(255,0,255);
  }
}