#help-modal {

  &.hidden {
    display: none;
  }

  & > * {
    z-index: 100;
  }

  .overlay-background {
    position: fixed;
    background-color: rgba(255, 255, 255, 0.5);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
  }

  .overlay-content {
    position: fixed;
    background-color: rgba(15, 15, 15, 0.85);
    top: 50%;
    left: 50%;
    max-height: 85vh;
    width: 70vw;
    padding: 0 30px;
    padding-bottom: 50px;
    border: 2px solid white;
    border-radius: 5px;
    overflow: auto;
    transform: translate(-50%, -50%);

    @media screen and (max-width: 768px) {
      width: 90vw;
    }

    .tip {
      padding: 30px;
      margin-bottom: 50px;
      border-top: 2px solid white;

      b {
        background-color: #ddd;
        padding: 0 15px 2px 10px;
        margin-right: 10px;
        color: #333;
        text-shadow: #fff 0px 0px 3px, #fff 1px 1px 1px;
      }
    }

    .play-button {
      text-align: center;

      button {
        position: relative;
        transform: scale(1.5);

        &:before {
          position: absolute;
          top: -6px;
          bottom: -6px;
          left: -6px;
          right: -6px;
          border:2px solid white;
          border-radius: inherit;
          content: '';
          z-index: -1;
          opacity: 0;
          transition: all 0.3s;
        }

        &:hover:before {
          opacity: 1;
        }
      }
    }

    h1, h2, p {
      text-shadow: #000 1px 1px 1px, #333 0px 0px 3px;
      font-size: 0.8em;
    }

    h1 {
      position: relative;
      font-size: 2em;
      color: rgb(90, 241, 204);
      text-align: center;
    }

    h2 {
      margin-top: 50px;
    }

    h2 ~ p:not(.img, .play-button, .tip) {
      margin-left: 30px;
    }

    p {
      margin: 0;
    }

    p.img {
      padding: 50px;
      text-align: center;
    
      img {
        max-height: 50%;
        max-width: 50%;
        box-shadow: #333 0px 3px 3px 1px;
      }

      @media screen and (max-width: 768px) {
        margin-left: -50px;
        margin-right: -50px;

        img {
          max-width: 100%;
        }
      }
    }
  }
}