$dot-size: 10px;
$dot-color: white;
$dot-color-active: black;

#header-stepper {
  display: block;
  position: relative;
  width: 100%;
  margin: 20px 0 40px 0;
  text-align: center;

  .dot {
    display: inline-block;
    background-color: $dot-color;
    width: $dot-size;
    height: $dot-size;
    margin: 0 30px;
    font-size: $dot-size * 7 / 10;
    line-height: $dot-size;
    font-weight: bold;
    text-align: center;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.8) 2px 2px 1px 1px;
    transition: all 0.3s;

    &:not(.active) {
      user-select: none;
      opacity: 0.7;
    }

    &.active {
      box-shadow: rgba(0, 0, 0, 0.6) 1px 1px 1px 0px;
      transform: scale(3);
      color: $dot-color-active;
    }
  }
}