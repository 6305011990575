$buttonMenuHeight: 50px + 20px + 10px;

#header {
  cursor: pointer;
  user-select: none;
}

#body {
  display: block;
  position: relative;
  align-self: stretch;
  height: 100%;
  width: 100vw;
  margin: 0 auto;
  overflow: hidden;

  & > .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: calc(100% - #{$buttonMenuHeight});
  }

  .menu {
    position: absolute;
    bottom: 20px;
    width: 100%;
    max-width: 70vw;
    text-align: right;
  }
}

#reverse-tile-game {
  background-color: #9e52d0;
  background-image: radial-gradient(
    ellipse at top -0vw right -0vw,
    rgb(28, 165, 158),
    transparent 100vw
  );
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

  .help-button {
    position: fixed;
    top: 40/9 * 1vh;
    right: 100 / 16 * 1vw;
    height: 50px;
    width: 50px;
    font-size: 1em;
    line-height: 1;
    padding: 0;
    border-radius: 50%;
    text-align: center;

    @media screen and (max-width: 768px) {
      top: 80/9 * 1vh;
      transform: scale(0.8);
    }
  }
}

button {
  &.underlined,
  &.outlined,
  &.full {
    display: inline-block;
    position: relative;
    padding: 10px 20px;
    margin: 5px 10px;
    border-radius: 5px;
    text-align: left;
    font-weight: bold;
    box-shadow: #333 0px 1px 0px 1px;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      box-shadow: #333 0px 3px 3px 1px;
    }
  }

  &.underlined {
    background-color: transparent;
    padding-left: 5px;
    padding-right: 5px;
    border: none;
    border-bottom: 2px solid white;
    color: white;
  }
  
  &.outlined {
    background-color: transparent;
    border: 2px solid white;
    color: white;
  }
  
  &.full {
    background-color: white;
    border: 2px solid white;
    color: #333;
  }

  &.float {
    float: left;
  }
}